// Auto-generated file: Do not modify directly. Changes may be overwritten. Version: 1.2.0.

/*
* Following tokens have been deprecated in version 1.2.0, please update your theme:
*
* Interactive/Focus has been split into two separate tokens. Please define 'Interactive/Focus Inner' and 'Interactive/Focus Outer' instead.
* 'Interactive/Background Default' is deprecated. Please use 'Input/Background Default' instead.
* 'Interactive/Background Disabled' is deprecated. Please use 'Input/Background Disabled' instead.
* 'Interactive/Background Hovered' is deprecated. Please use 'Input/Background Hovered' instead.
* 'Interactive/Background Pressed' is deprecated. Please use 'Input/Background Pressed' instead.
* 'Interactive/Border Default' is deprecated. Please use 'Input/Border Default' instead.
* 'Interactive/Border Hovered' is deprecated. Please use 'Input/Border Hovered' instead.
* 'Interactive/Border Disabled' is deprecated. Please use 'Input/Border Disabled' instead.
* 'Interactive/Border Critical Default' is deprecated. Please use 'Input/Border Critical' instead.
* 'Pill' is deprecated. Please use 'Circle' instead.
*/

import type { GDSTheme } from '@leagueplatform/genesis-core';

export const beneficityTheme: GDSTheme = {
  colors: {
    surface: {
      background: {
        primary: '#FFFFFFFF',
        secondary: '#ECF7FFFF',
        tertiary: '#C5E6FFFF'
      },
      card: {
        primary: '#FFFFFFFF',
        secondary: '#F5F6F6FF',
        disabled: '#E0E2E4FF'
      },
      screenHeader: { background: '#DEF3FCFF' },
      skeleton: {
        shimmer: {
          background: '#E7E7E7FF',
          overlay: 'linear-gradient(90.0deg, #FFFFFF00 0%, #FFFFFF80 50%, #FFFFFFE5 70%, #FFFFFF00 100%)'
        }
      }
    },
    onSurface: {
      text: {
        primary: '#00223CFF',
        subdued: '#495055FF',
        reversed: '#FFFFFFFF',
        critical: '#A12127FF',
        success: '#0A884BFF',
        warning: '#00223CFF'
      },
      border: { subdued: '#E0E2E4FF', default: '#B6BCC0FF', card: '#E0E2E4FF' },
      icon: { primary: '#6D787FFF' }
    },
    interactive: {
      action: {
        primary: '#0066B1FF',
        hovered: '#003963FF',
        pressed: '#00223CFF',
        subdued: '#6D787FFF',
        disabled: '#E0E2E4FF'
      },
      icon: { default: '#FFFFFFFF', disabled: '#6D787FFF' },
      background: {
        default: '#FFFFFFFF',
        disabled: '#E7E7E7FF',
        hovered: '#ECF7FFFF',
        pressed: '#C5E6FFFF'
      },
      border: {
        default: '#6D787FFF',
        hovered: '#003963FF',
        disabled: '#A7AEB2FF',
        critical: { default: '#D30034FF' }
      },
      focus: { inner: '#0066B1FF', outer: '#0066B1FF' }
    },
    critical: {
      background: { subdued: '#FFE6ECFF', secondary: '#FFFFFFFF' },
      border: { default: '#D30034FF' },
      icon: '#D30034FF'
    },
    warning: {
      background: { subdued: '#FFF6E0FF', secondary: '#FFFFFFFF' },
      border: { default: '#FCCA46FF' },
      icon: '#FCCA46FF'
    },
    success: {
      background: { subdued: '#E3FDF0FF', secondary: '#FFFFFFFF' },
      border: { default: '#0A884BFF' },
      icon: '#0A884BFF'
    },
    highlight: {
      background: { subdued: '#ECF7FFFF', secondary: '#FFFFFFFF' },
      border: { default: '#0066B1FF' },
      icon: '#0066B1FF'
    },
    decorative: {
      brand: {
        primary: {
          darkest: '#00223CFF',
          dark: '#003963FF',
          default: '#0066B1FF',
          bright: '#55B7FFFF',
          brightest: '#C5E6FFFF',
          pastel: '#ECF7FFFF'
        },
        secondary: {
          darkest: '#096D68FF',
          dark: '#0C918BFF',
          default: '#0FB5AEFF',
          bright: '#F7987DFF',
          brightest: '#FDE1D9FF',
          pastel: '#FEF0ECFF'
        },
        tertiary: {
          darkest: '#3E1F99FF',
          dark: '#5329CCFF',
          default: '#6833FFFF',
          bright: '#FFF689FF',
          brightest: '#FFFAC4FF',
          pastel: '#FFFDEBFF'
        }
      }
    },
    primary: {
      background: {
        default: '#0066B1FF',
        hovered: '#003963FF',
        pressed: '#00223CFF',
        critical: {
          default: '#D30034FF',
          hovered: '#AC002AFF',
          pressed: '#850021FF'
        }
      },
      text: { default: '#FFFFFFFF', critical: { default: '#FFFFFFFF' } }
    },
    secondary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#003963FF',
        pressed: '#00223CFF'
      },
      text: { default: '#0066B1FF', hovered: '#FFFFFFFF' },
      border: { default: '#0066B1FF' }
    },
    tertiary: {
      background: {
        default: '#FFFFFF00',
        hovered: '#ECF7FFFF',
        pressed: '#C5E6FFFF'
      },
      text: { default: '#0066B1FF', hovered: '#003963FF' },
      border: { default: '#6D787FFF' }
    },
    input: {
      border: {
        success: '#0A884BFF',
        warning: '#FCCA46FF',
        default: '#6D787FFF',
        hovered: '#003963FF',
        disabled: '#A7AEB2FF',
        critical: '#D30034FF'
      },
      background: {
        default: '#FFFFFFFF',
        disabled: '#E7E7E7FF',
        hovered: '#ECF7FFFF',
        pressed: '#C5E6FFFF'
      }
    },
    dataViz: {
      interface: { track: { border: '#959595FF', background: '#F5F5F5FF' } },
      singleColor: { brand: '#008BCCFF', neutral: '#959595FF' },
      status: {
        critical: '#CB2A32FF',
        warning: '#DE6F12FF',
        success: '#159369FF'
      },
      categorical: {
        one: '#008BCCFF',
        two: '#074664FF',
        three: '#00699AFF',
        four: '#C2891BFF',
        five: '#845A09FF',
        six: '#008B82FF'
      }
    }
  },
  shadows: {
    card: { x: 0, y: 2, blur: 6, spread: 0, color: '#00000014' },
    dropdown: { x: 0, y: 4, blur: 8, spread: 0, color: '#0000001A' }
  },
  typography: {
    headingOne: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 41.599998474121094,
      fontSize: 32,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    headingTwo: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 39,
      fontSize: 26,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    headingThree: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 30,
      fontSize: 20,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    headingFour: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 28.80000114440918,
      fontSize: 18,
      letterSpacing: 0.25,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    subtitleOne: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 25.600000381469727,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    subtitleTwo: {
      fontFamily: '"Poppins", "Outfit", "Helvetica", sans-serif',
      fontWeight: 600,
      lineHeight: 22.399999618530273,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    bodyOne: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 25.600000381469727,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none'
    },
    bodyOneSecondary: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 25.600000381469727,
      fontSize: 16,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none'
    },
    bodyTwo: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 22.399999618530273,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none'
    },
    bodyTwoSecondary: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 22.399999618530273,
      fontSize: 14,
      letterSpacing: 0,
      paragraphSpacing: 8,
      textCase: 'none',
      textDecoration: 'none'
    },
    buttonOne: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 600,
      lineHeight: 25.600000381469727,
      fontSize: 16,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    buttonTwo: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 600,
      lineHeight: 22.399999618530273,
      fontSize: 14,
      letterSpacing: -0.20000000298023224,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    label: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    overline: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 700,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0.6000000238418579,
      paragraphSpacing: 0,
      textCase: 'UPPER',
      textDecoration: 'none'
    },
    caption: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 16,
      fontSize: 12,
      letterSpacing: 0,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    },
    tab: {
      fontFamily: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif',
      fontWeight: 400,
      lineHeight: 12,
      fontSize: 10,
      letterSpacing: 0.1599999964237213,
      paragraphSpacing: 0,
      textCase: 'none',
      textDecoration: 'none'
    }
  },
  spacing: {
    none: 0,
    quarter: 4,
    half: 8,
    threeQuarters: 12,
    one: 16,
    oneAndHalf: 24,
    two: 32,
    twoAndHalf: 40,
    three: 48,
    four: 64,
    five: 80
  },
  borderWidths: { thin: 1, thick: 2, outerFocus: 3, innerFocus: 3, card: 1 },
  borderRadii: {
    none: 0,
    thin: 1,
    thick: 2,
    small: 3,
    medium: 6,
    large: 9,
    extraLarge: 12,
    huge: 20,
    circle: 99999,
    pill: 99999,
    button: 99999,
    inputField: 6,
    card: 6
  },
  zIndices: {
    default: 1,
    sticky: 100,
    dropdown: 4000,
    tooltip: 5000,
    modal: 8000,
    toast: 9000
  },
  rem: {
    fontSizes: {
      headingOne: 2,
      headingTwo: 1.625,
      headingThree: 1.25,
      headingFour: 1.125,
      subtitleOne: 1,
      subtitleTwo: 0.875,
      bodyOne: 1,
      bodyOneSecondary: 1,
      bodyTwo: 0.875,
      bodyTwoSecondary: 0.875,
      buttonOne: 1,
      buttonTwo: 0.875,
      label: 0.75,
      overline: 0.75,
      caption: 0.75,
      tab: 0.625
    },
    lineHeights: {
      headingOne: 2.5999999046325684,
      headingTwo: 2.4375,
      headingThree: 1.875,
      headingFour: 1.8000000715255737,
      subtitleOne: 1.600000023841858,
      subtitleTwo: 1.399999976158142,
      bodyOne: 1.600000023841858,
      bodyOneSecondary: 1.600000023841858,
      bodyTwo: 1.399999976158142,
      bodyTwoSecondary: 1.399999976158142,
      buttonOne: 1.600000023841858,
      buttonTwo: 1.399999976158142,
      label: 1,
      overline: 1,
      caption: 1,
      tab: 0.75
    },
    baseSize: 16
  },
  fonts: {
    headings: '"Poppins", "Outfit", "Helvetica", sans-serif',
    body: 'Roboto, "Helvetica", "Segoe UI", "Arial", sans-serif'
  },
  transitions: { defaultTime: '200ms' }
}